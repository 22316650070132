.hp-image {
    width: 100%;
    height: 960px;
    height: 100vh;
    background-size: cover;
    background-position: 50% 50%;
}

.hp-slideshow {
    height: 100%;

    .slick-list {
        height: 100%;
    }

    .slick-track {
        height: 100%;
    }

    .slick-slide {
        height: 100%;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        outline: none;
    }
}

.hp-footer {
    &:extend(.main-footer all);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    border-top: 1px solid #fff;
    z-index: 1;
}

.hp-scrorrow {
    .nobtn();
    .notxt();
    .sprite(@scrorrow);
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-bottom: 25px;
    margin-left: -@scrorrow-width / 2;
    cursor: pointer;

    &:hover {
        opacity: .8;
    }
}

.hp-wrapper {
    .wrapper(1024px, 30px);
    .cf();
    font-size: 18px;
    padding: 3em -0px 4.44em;
}

.hp-heading {
    text-align: center;
    font-size: 3.33em;
    font-weight: 700;
    margin-bottom: 0.43em;
    text-transform: uppercase;

    span {
        display: inline-block;
        padding-bottom: 0.43em;
        border-bottom: 1px solid @c1;
    }
}

.hp-foto {
    float: left;
    margin-right: -100%;
    margin-bottom: 30px;
    max-width: 300px;
}

.hp-text {
    padding-left: 345px;
    line-height: 1.17;

    p {
        margin-bottom: 1.17em;
    }
}

.hp-cta {
    clear: both;
    font-size: 27px;
    margin-top: 0.75em;
    padding-top: 0.75em;
    border-top: 1px solid #ddd;
    text-align: center;
    font-weight: 300;
    line-height: 1.5;

    .cta-text {
        display: inline-block;
    }

    a {
        font-size: 1.11em;
        font-weight: 400;
        color: #fff;
        background: @c1;
        display: inline-block;
        padding: 0.4em 0.8em;
        margin: 0 0.4em;
        line-height: 1;

        &:hover {
            background: @c1-hover;
        }
    }
}

@media (max-width: 1024px) {
    .hp-wrapper {
        font-size: 16px;
    }

    .hp-foto {
        max-width: 200px;
    }

    .hp-text {
        padding-left: 230px;
    }

    .hp-cta {
        font-size: 24px;
    }
}

@media (max-width: 640px) {
    .hp-foto {
        float: none;
        width: auto;
        max-width: 100%;
        display: block;
        margin: 0 auto 2em;
    }

    .hp-text {
        padding: 0;
    }

    .hp-cta {
        font-size: 22px;
    }
}
