/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
@back-icon-name: 'back-icon';
@back-icon-x: 121px;
@back-icon-y: 0px;
@back-icon-offset-x: -121px;
@back-icon-offset-y: 0px;
@back-icon-width: 18px;
@back-icon-height: 31px;
@back-icon-total-width: 139px;
@back-icon-total-height: 124px;
@back-icon-image: '/dist/img/sprite.png';
@back-icon: 121px 0px -121px 0px 18px 31px 139px 124px '/dist/img/sprite.png' 'back-icon';
@down-arrow-name: 'down-arrow';
@down-arrow-x: 81px;
@down-arrow-y: 76px;
@down-arrow-offset-x: -81px;
@down-arrow-offset-y: -76px;
@down-arrow-width: 37px;
@down-arrow-height: 21px;
@down-arrow-total-width: 139px;
@down-arrow-total-height: 124px;
@down-arrow-image: '/dist/img/sprite.png';
@down-arrow: 81px 76px -81px -76px 37px 21px 139px 124px '/dist/img/sprite.png' 'down-arrow';
@home-icon-name: 'home-icon';
@home-icon-x: 0px;
@home-icon-y: 101px;
@home-icon-offset-x: 0px;
@home-icon-offset-y: -101px;
@home-icon-width: 23px;
@home-icon-height: 23px;
@home-icon-total-width: 139px;
@home-icon-total-height: 124px;
@home-icon-image: '/dist/img/sprite.png';
@home-icon: 0px 101px 0px -101px 23px 23px 139px 124px '/dist/img/sprite.png' 'home-icon';
@hover-icon-name: 'hover-icon';
@hover-icon-x: 121px;
@hover-icon-y: 32px;
@hover-icon-offset-x: -121px;
@hover-icon-offset-y: -32px;
@hover-icon-width: 18px;
@hover-icon-height: 24px;
@hover-icon-total-width: 139px;
@hover-icon-total-height: 124px;
@hover-icon-image: '/dist/img/sprite.png';
@hover-icon: 121px 32px -121px -32px 18px 24px 139px 124px '/dist/img/sprite.png' 'hover-icon';
@left-arrow-name: 'left-arrow';
@left-arrow-x: 88px;
@left-arrow-y: 33px;
@left-arrow-offset-x: -88px;
@left-arrow-offset-y: -33px;
@left-arrow-width: 21px;
@left-arrow-height: 37px;
@left-arrow-total-width: 139px;
@left-arrow-total-height: 124px;
@left-arrow-image: '/dist/img/sprite.png';
@left-arrow: 88px 33px -88px -33px 21px 37px 139px 124px '/dist/img/sprite.png' 'left-arrow';
@mail-icon-name: 'mail-icon';
@mail-icon-x: 51px;
@mail-icon-y: 101px;
@mail-icon-offset-x: -51px;
@mail-icon-offset-y: -101px;
@mail-icon-width: 22px;
@mail-icon-height: 16px;
@mail-icon-total-width: 139px;
@mail-icon-total-height: 124px;
@mail-icon-image: '/dist/img/sprite.png';
@mail-icon: 51px 101px -51px -101px 22px 16px 139px 124px '/dist/img/sprite.png' 'mail-icon';
@mobile-icon-name: 'mobile-icon';
@mobile-icon-x: 121px;
@mobile-icon-y: 57px;
@mobile-icon-offset-x: -121px;
@mobile-icon-offset-y: -57px;
@mobile-icon-width: 16px;
@mobile-icon-height: 22px;
@mobile-icon-total-width: 139px;
@mobile-icon-total-height: 124px;
@mobile-icon-image: '/dist/img/sprite.png';
@mobile-icon: 121px 57px -121px -57px 16px 22px 139px 124px '/dist/img/sprite.png' 'mobile-icon';
@more-icon-name: 'more-icon';
@more-icon-x: 24px;
@more-icon-y: 101px;
@more-icon-offset-x: -24px;
@more-icon-offset-y: -101px;
@more-icon-width: 26px;
@more-icon-height: 16px;
@more-icon-total-width: 139px;
@more-icon-total-height: 124px;
@more-icon-image: '/dist/img/sprite.png';
@more-icon: 24px 101px -24px -101px 26px 16px 139px 124px '/dist/img/sprite.png' 'more-icon';
@next-icon-name: 'next-icon';
@next-icon-x: 44px;
@next-icon-y: 0px;
@next-icon-offset-x: -44px;
@next-icon-offset-y: 0px;
@next-icon-width: 43px;
@next-icon-height: 75px;
@next-icon-total-width: 139px;
@next-icon-total-height: 124px;
@next-icon-image: '/dist/img/sprite.png';
@next-icon: 44px 0px -44px 0px 43px 75px 139px 124px '/dist/img/sprite.png' 'next-icon';
@prev-icon-name: 'prev-icon';
@prev-icon-x: 0px;
@prev-icon-y: 0px;
@prev-icon-offset-x: 0px;
@prev-icon-offset-y: 0px;
@prev-icon-width: 43px;
@prev-icon-height: 75px;
@prev-icon-total-width: 139px;
@prev-icon-total-height: 124px;
@prev-icon-image: '/dist/img/sprite.png';
@prev-icon: 0px 0px 0px 0px 43px 75px 139px 124px '/dist/img/sprite.png' 'prev-icon';
@scrorrow-name: 'scrorrow';
@scrorrow-x: 0px;
@scrorrow-y: 76px;
@scrorrow-offset-x: 0px;
@scrorrow-offset-y: -76px;
@scrorrow-width: 42px;
@scrorrow-height: 24px;
@scrorrow-total-width: 139px;
@scrorrow-total-height: 124px;
@scrorrow-image: '/dist/img/sprite.png';
@scrorrow: 0px 76px 0px -76px 42px 24px 139px 124px '/dist/img/sprite.png' 'scrorrow';
@up-arrow-name: 'up-arrow';
@up-arrow-x: 43px;
@up-arrow-y: 76px;
@up-arrow-offset-x: -43px;
@up-arrow-offset-y: -76px;
@up-arrow-width: 37px;
@up-arrow-height: 21px;
@up-arrow-total-width: 139px;
@up-arrow-total-height: 124px;
@up-arrow-image: '/dist/img/sprite.png';
@up-arrow: 43px 76px -43px -76px 37px 21px 139px 124px '/dist/img/sprite.png' 'up-arrow';
@zoom-icon-name: 'zoom-icon';
@zoom-icon-x: 88px;
@zoom-icon-y: 0px;
@zoom-icon-offset-x: -88px;
@zoom-icon-offset-y: 0px;
@zoom-icon-width: 32px;
@zoom-icon-height: 32px;
@zoom-icon-total-width: 139px;
@zoom-icon-total-height: 124px;
@zoom-icon-image: '/dist/img/sprite.png';
@zoom-icon: 88px 0px -88px 0px 32px 32px 139px 124px '/dist/img/sprite.png' 'zoom-icon';
@spritesheet-width: 139px;
@spritesheet-height: 124px;
@spritesheet-image: '/dist/img/sprite.png';
@spritesheet-sprites: @back-icon @down-arrow @home-icon @hover-icon @left-arrow @mail-icon @mobile-icon @more-icon @next-icon @prev-icon @scrorrow @up-arrow @zoom-icon;
@spritesheet: 139px 124px '/dist/img/sprite.png' @spritesheet-sprites;

/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}
*/
.sprite-width(@sprite) {
  width: extract(@sprite, 5);
}

.sprite-height(@sprite) {
  height: extract(@sprite, 6);
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 3);
  @sprite-offset-y: extract(@sprite, 4);
  background-position: @sprite-offset-x @sprite-offset-y;
}

.sprite-image(@sprite) {
  @sprite-image: extract(@sprite, 9);
  @sprite-image-bare: ~`"@{sprite-image}".slice(1, -1)`;
  background-image: url(@sprite-image-bare);
}

.sprite(@sprite) {
  .sprite-image(@sprite);
  .sprite-position(@sprite);
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.sprites(@sprites, @i: 1) when (@i <= length(@sprites)) {
  @sprite: extract(@sprites, @i);
  @sprite-name: e(extract(@sprite, 10));
  .@{sprite-name} {
    .sprite(@sprite);
  }
  .sprites(@sprites, @i + 1);
}
