.nobtn() {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    -webkit-apperance: none;
    -moz-apperance: none;
    apperance: none;
}

.wrapper(@width, @padding) {
    width: @width;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: (@width + 2 * @padding)) {
        width: auto;
        margin-left: @padding;
        margin-right: @padding;
    }
}

.cf() {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.notxt() {
    text-align: left;
    direction: ltr;
    text-indent: -9999px;
    overflow: hidden;
}
