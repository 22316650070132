body {
    font-family: Ubuntu, sans-serif;
    color: #333;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: @c1;
}

strong,
b {
    font-weight: bolder;
}

em,
i {
    font-style: italic;
}

form {
    label {
        display: none;
    }

    input,
    textarea {
        .nobtn();
        background: #eef5db;
        display: block;
        width: 100%;
        padding: 0.56em;
        font-family: inherit;
        font-size: inherit;
        margin: 15px 0 0;
    }

    p {
        margin-bottom: 20px;
        font-weight: 700;
    }

    textarea {
        height: 10em;
    }

    input[type=submit] {
        font-size: 1.17em;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        background: @c1;
        color: #fff;
        cursor: pointer;
        margin-bottom: 20px;

        &:hover {
            background: @c1-hover;
        }
    }
}

.main-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    margin-top: 0.6%;
    margin-left: 2.5%;
    max-width: 20%;
    min-width: 150px;
    z-index: 1;

    @media (max-width: 340px) {
        margin-left: 0;
        left: 5%;
        right: 10%;
        width: 80%;
        max-width: 40%;
    }
}

.main-menu {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5% 5% 2.73% 6.64%;
    height: 100%;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    overflow: hidden;
    z-index: 1;

    .subpage-gallery & {
        transition: right @gallery-close-transition;
        right: 21%;

        &.is-open {
            z-index: 2;
        }

        @media (max-width: 1428px) {
            right: 300px;

        }
        @media (max-width: 800px) {
            right: 0;
            height: auto;
        }
    }

    .subpage-gallery.is-sidebar-open & {
        right: 0;
    }

    &:before {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        width: 100%;
        height: 100%;
        background: #1b1b1b;
        transition: left 0.4s;
    }

    a {
        display: block;
        padding: 0.2em 0;
        color: #fff;
        position: relative;
        transition: color 0.2s;
        outline: none;

        &:before {
            content: '';
            position: absolute;
            left: -@hover-icon-width - 10px;
            top: 50%;
            margin-top: -@hover-icon-height / 2;
            .sprite(@hover-icon);
            opacity: 0;
            transition: opacity 0.2s;
            pointer-events: none;
        }

        &:hover,
        &:focus {
            color: @c1;

            &:before {
                opacity: 1;
            }
        }
    }

    ul {
        position: relative;
        left: 150%;
        opacity: 0;
        visibility: hidden;
        transition: left 0.4s, opacity 0.4s, visibility 0s 0.4s;
    }

    &.is-open {
        &:before {
            left: 0;
        }

        ul {
            left: 0;
            opacity: 1;
            transition-delay: 0.1s, 0.1s, 0s;
            visibility: visible;
        }
    }

    &.fixed {
        position: fixed;
        padding-top: 10%;

        @media (max-width: 640px) {
            padding-top: 65px;
        }
    }

    @media (max-width: 1280px) {
        font-size: 26px;
    }

    @media (max-width: 800px) {
        font-size: 22px;
        padding-left: 50px;
    }
}

.main-menu-toggle-wrapper {
    &:extend(.main-menu);
    height: auto;
    z-index: 2;
}

.main-menu-toggle {
    .nobtn();
    outline: none;
    position: relative;
    font-weight: 700;
    font-size: 1.07em;
    margin-top: 1em;
    margin-bottom: 21.25%;
    color: #fff;
    z-index: 1;
    cursor: pointer;
    transition: color 0.4s;

    .toggle-icon {
        position: relative;
        display: inline-block;
        vertical-align: 0.35em;
        margin-left: 0.63em;
        transition: background-color 0.4s;

        &,
        &:before,
        &:after {
            height: 0.13em;
            width: 1em;
            background: #fff;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            transition: background-color 0.4s, transform 0.4s;
        }

        &:before {
            transform: translateY(-0.26em);
        }

        &:after {
            transform: translateY(0.26em);
        }
    }

    &:hover,
    &:focus {
        color: @c1;

        .toggle-icon {
            &,
            &:before,
            &:after {
                background: @c1;
            }
        }
    }

    &.is-open {
        .toggle-icon {
            background: transparent;

            &:before {
                transform: rotateZ(45deg);
            }

            &:after {
                transform: rotateZ(-45deg);
            }
        }
    }
}

.main-footer {
    font-size: 18px;
    line-height: 1.11;
    padding: 1.11em 30px;
    text-align: center;
    background: mix(@c1, #fff, 80%);
    color: #fff;

    a {
        color: inherit;
        font-weight: 700;

        &:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: 640px) {
        font-size: 14px;
    }
}

.page-header {
    padding-top: 18%;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;

    @media (max-width: 1945px) {
        height: 350px;
        padding-top: 0;
    }

    @media (max-width: 800px) {
        height: 250px;
    }
}

.page-wrapper {
    .wrapper(1015px, 30px);
    padding: 2.5em 0;
    font-size: 18px;

    @media (max-width: 1280px) {
        font-size: 16px;
    }

    @media (max-width: 800px) {
        font-size: 14px;
    }
}

.page-content {
    background: url('/dist/img/bg-left.jpg') 0 0 no-repeat;
    background: url('/dist/img/bg-left.jpg') 0 0 no-repeat,
                url('/dist/img/bg-right.jpg') 100% 0 no-repeat;

    @media (max-width: 1330px) {
        background: none;
    }
}

.page-heading {
    font-size: 3.33em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 0.43em;
    margin-bottom: 0.43em;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        width: 240px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: @c1;
    }
}

.page-text {
    line-height: 1.5em;
    margin-bottom: 2.56em;
}

.page-cols {
    .cf();
    margin: 0 -20px;
}

.page-col {
    width: 50%;
    float: left;
    padding: 0 20px;

    @media (max-width: 640px) {
        width: 100%;
    }
}
