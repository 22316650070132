.subpage-gallery {
    overflow: hidden;

    @media (min-width: 801px) {
        &.is-sidebar-open {
            .gal-main {
                right: 0;
            }

            .gal-sidebar {
                overflow: visible;
                transform: translateX(100%);
                z-index: 1;
            }

            .gal-sidebar-toggle {
                right: 0;

                &:before {
                    transform: none;
                }
            }
        }
    }
}

.gal-main {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    right: 21%;
    background: #1b1b1b;
    transition: right @gallery-close-transition;

    .slick-list {
        height: 100%;
    }

    .slick-track {
        height: 100%;
    }

    .slick-slide {
        height: 100%;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        outline: none;
    }

    .slick-prev,
    .slick-next {
        .nobtn();
        .notxt();
        position: absolute;
        top: 50%;
        cursor: pointer;
        z-index: 1;

        @media (max-width: 800px) {
            transform: scale(.5, .5);
        }
    }

    .slick-prev {
        .sprite(@prev-icon);
        margin-left: 10px;
        margin-top: -@prev-icon-height / 2;
        left: 0.15%;
        transform-origin: left center;
    }

    .slick-next {
        .sprite(@next-icon);
        margin-right: 10px;
        margin-top: -@next-icon-height / 2;
        right: 0.15%;
        transform-origin: right center;
    }

    @media (max-width: 1428px) {
        right: 300px;
    }

    .gal-zoom {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 10px;
        background: #fff;
        width: @zoom-icon-width + 20px;
        height: @zoom-icon-height + 20px;
        .notxt();

        &:before {
            content: '';
            position: absolute;
            top: 10px;
            left: 10px;
            display: block;
            .sprite(@zoom-icon);
        }

        @media(max-width: 800px) {
            display: none;
        }
    }
}

.gal-sidebar {
    position: absolute;
    right: 0;
    top: 0;
    width: 21%;
    height: 100%;
    overflow: auto;
    background: url('/dist/img/gal-bg.jpg') 100% 0 no-repeat;
    transition: transform @gallery-close-transition;

    @media (max-width: 1428px) {
        width: 300px;
    }
}

.gal-sidebar-toggle {
    display: block;
    position: absolute;
    bottom: 0;
    right: 21%;
    transition: right @gallery-close-transition;
    .nobtn();
    .notxt();
    padding: 10px;
    width: @left-arrow-width + 20;
    height: @left-arrow-height + 20;
    background-color: #fff;
    cursor: pointer;
    z-index: 1;

    @media (max-width: 1428px) {
        right: 300px;
    }

    @media (max-width: 800px) {
        display: none;
    }

    &:before {
        position: absolute;
        top: 10px;
        left: 10px;
        content: '';
        display: block;
        .sprite(@left-arrow);
        transform: scaleX(-1);
    }
}

.gal-back {
    display: block;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    padding: 0.56em 0;
    color: #fff;
    background: @c1;

    &:before {
        content: '';
        display: inline-block;
        .sprite(@back-icon);
        margin-right: .5em;

        @media (max-width: 800px) {
            transform: scale(.5, .5);
            margin-top: -.25 * @back-icon-height;
            margin-bottom: -.25 * @back-icon-height;
        }
    }

    &:hover {
        background: @c1-hover;
    }

    @media (max-width: 1280px) {
        font-size: 32px;
    }
}

.gal-content {
    padding: 9.26% 6.48%;
    font-size: 18px;
    line-height: 1.17;

    @media (max-width: 1280px) {
        font-size: 16px;
    }

    @media (max-width: 800px) {
        font-size: 14px;
    }
}

.gal-heading {
    font-size: 1.83em;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.91em;

    &:after {
        content: '';
        display: block;
        height: 1px;
        background: #e5e5e5;
        width: 65%;
        margin: 0.3em auto 0;
    }
}

.gal-text {
    margin-bottom: 1.78em;
}

.gal-nav {
    width: 350px;
    margin: 0 auto 30px;
    max-width: 100%;

    .slick-slide {
        outline: none;
        background: @c1;
        border-style: solid;
        border-width: 0 0 10px;
        border-color: #fff;
    }

    img {
        max-width: 100%;
        vertical-align: top;
        transition: opacity 0.4s;
    }

    .slick-current img {
        opacity: 0.45;
    }

    .slick-prev,
    .slick-next {
        .nobtn();
        .notxt();
        display: block;
        margin: 0 auto;
        cursor: pointer;
    }

    .slick-prev {
        .sprite(@up-arrow);
        margin-bottom: 1em;
    }

    .slick-next {
        .sprite(@down-arrow);
        margin-top: 1em;
    }
}

@media (max-width: 800px) {
    .gal-main {
        position: relative;
        right: 0;
        height: auto;

        .slick-list {
            height: auto;
        }

        .slick-track {
            height: auto;
        }

        .slick-slide {
            height: 320px;
            background-size: cover;
        }
    }

    .gal-sidebar {
        position: relative;
        width: auto;
    }

    .gal-nav {
        display: none;
    }

    .gal-back {
        font-size: 24px;
    }
}
