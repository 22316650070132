@font-face {
  font-family: "iconfont";
  src: url('../fonts/iconfont.eot');
  src: url('../fonts/iconfont.eot?#iefix') format('eot'),
    url('../fonts/iconfont.woff2') format('woff2'),
    url('../fonts/iconfont.woff') format('woff'),
    url('../fonts/iconfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.icon-clock:before {
  content: "\EA01";
}
.icon-envelop-plain:before {
  content: "\EA02";
}
.icon-envelop:before {
  content: "\EA03";
}
.icon-eye:before {
  content: "\EA04";
}
.icon-facebook:before {
  content: "\EA05";
}
.icon-glass:before {
  content: "\EA06";
}
.icon-globe-check:before {
  content: "\EA07";
}
.icon-globe:before {
  content: "\EA08";
}
.icon-google-plus:before {
  content: "\EA09";
}
.icon-grid-view:before {
  content: "\EA0A";
}
