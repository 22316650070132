.list-cells {
    .cf();
    margin: 0 -20px 1em;
    padding-bottom: 1em;
    position: relative;
}

.list-cell {
    width: 50%;
    float: left;
    padding: 0 20px;
    margin-bottom: 1.44em;

    &:after {
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        bottom: 0;
        left: 20px;
        right: 20px;
        margin: auto;
        background: #e5e5e5;
    }

    .cell-heading {
        text-align: center;
        font-size: 1.67em;
        font-weight: 700;
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            display: block;
            height: 1px;
            width: 60%;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background: #e5e5e5;
        }
    }

    .cell-image {
        display: block;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: url('/dist/img/zoom.png') 50% 50% no-repeat fade(@c1, 70%);
            opacity: 0;
            transition: opacity 0.4s;
            z-index: 1;
        }

        &:hover:after {
            opacity: 1;
        }
    }

    img {
        width: 100%;
        display: block;
    }

    @media (max-width: 640px) {
        width: 100%;
    }
}

.list-more {
    text-align: center;

    button,
    a {
        .nobtn();
        font-size: 1.11em;
        text-transform: uppercase;
        cursor: pointer;
        color: inherit;

        &:after {
            content: '';
            display: inline-block;
            .sprite(@more-icon);
            margin-left: 0.56em;
        }
    }
}
