.contact-text {
    line-height: 1.33;
}

.contact-table {
    width: 100%;
    margin: 1.44em 0;
    border-top: 1px solid #e5e5e5;

    th,
    td {
        padding: 0.56em 0;
    }

    th {
        font-weight: 700;
        padding-right: 0.56em;
        text-align: left;
        white-space: nowrap;
    }

    tr:first-child {
        th,
        td {
            padding-top: 1.11em;
        }
    }

    @icon-width: max(@home-icon-width, @mail-icon-width, @mobile-icon-width);

    .home-icon {
        display: inline-block;
        .sprite(@home-icon);
        vertical-align: middle;
        margin-right: @icon-width - @home-icon-width + 8px;
    }

    .mail-icon {
        display: inline-block;
        .sprite(@mail-icon);
        margin-right: @icon-width - @mail-icon-width + 8px;
    }

    .mobile-icon {
        display: inline-block;
        .sprite(@mobile-icon);
        margin-right: @icon-width - @mobile-icon-width + 8px;
    }
}
