.wsw {
    font-size: 16px;
    line-height: 1.5;

    .page-wrapper & {
        font-size: 0.89em;
    }

    img {
        margin: 2em 0;
        max-width: 100%;

        &:first-child {
            margin-top: 0;
        }
    }

    h2,
    h3 {
        font-weight: 700;
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 61.22%;
            background: #e5e5e5;
        }
    }

    h2 {
        font-size: 1.88em;
    }

    h3 {
        font-size: 1.22em;
    }

    p {
        margin-bottom: 1.5em;
        text-align: justify;
    }
}
